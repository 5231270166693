import 'mdn-polyfills/NodeList.prototype.forEach';
import Loadix from './Loadix';

document.addEventListener('DOMContentLoaded', () => {
  const loadix = document.querySelectorAll('.js-loadix');
  if (!loadix.length) {
    return;
  }

  loadix.forEach((element) => {
    const loadix = new Loadix(element);
    loadix.mount(element);
  });
});
