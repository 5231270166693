import Details from './Details';

document.addEventListener('DOMContentLoaded', () => {
  const detailsElements = document.querySelectorAll('.js-details');
  if (!detailsElements.length) {
    return;
  }
  detailsElements.forEach((element,index) => {
    const detailsElement = new Details(element, {
      hasAdditionalControl: false,
    });
    detailsElement.mount();
    // if (index == 0) {
    //   detailsElement.open();
    // }
  });

  const detailsArray = Array.from(detailsElements);

  const allDetails = detailsArray.map((element) => {
    const detailsElement = new Details(element, {
      hasAdditionalControl: false,
    });
    detailsElement.mount();

    if (element.classList.contains('current')) {
      detailsElement.open();
    }
    return detailsElement;
  });

  function closeOthers(openElement) {
    allDetails.forEach(details => {
      if (details !== openElement && details.expanded) {
        details.close();
      }
    });
  }

  allDetails.forEach(detailsElement => {
    detailsElement.on('open', () => {
      closeOthers(detailsElement);
    });
  });





});


// const allDetails = detailsArray.map((element) => {
//   const detailsElement = new Details(element, {
//     hasAdditionalControl: false,
//   });
//   detailsElement.mount();
//   if (element.classList.contains('current')) {
//     detailsElement.open();
//   }
//   return detailsElement;
// });


