import "./front/components/Header/lifecycle";
import './front/components/Dropdown/lifecycle';
import './front/components/Breadcrumbs/lifecycle';
import './front/components/Details/lifecycle';
import './front/components/slider/lifecycle';
import './front/components/Loadix/lifecycle';
import './front/components/Glightbox/lifecycle';
import './front/components/Glightboxbutton/lifecycle';

let scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.documentElement.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`);

window.addEventListener('resize', function(event) {
  let scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.documentElement.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`);
}, true);
