import 'mdn-polyfills/NodeList.prototype.forEach';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

export default class Slider {
  constructor(element) {
    this.element = element;
    this.next = this.element.querySelector(".swiper-button-next .c-button");
    this.prev = this.element.querySelector(".swiper-button-prev .c-button");
    this.pagination = this.element.querySelector(".c-slider__pagination");

    const slides = this.element.querySelectorAll(".swiper-slide");
    const slideCount = slides.length;



    this.swiperElement = this.element;

    this.swiperElement = this.element;

    this.innerWidth = window.innerWidth;

    this.handleResize = this.handleResize.bind(this);
    this.handleResize = this.handleResize.bind(this);

    this.paginationValues;
    this.autoplayValues;
    this.navigationValues;
    this.onChange;

    this.paginationValues = {
      el: ".swiper-pagination",
      type: "fraction",
    }

    if (this.swiperElement.classList.contains("c-slider--product")) {

      this.autoplayValues = {
        delay: 5000,
      }
      this.paginationValues = {
        el: ".swiper-pagination",
        clickable: true,
      }
      this.navigationValues = false;

      this.onChangeValue =  {
        autoplayTimeLeft(s, time, progress) {
          const progressCircle = document.querySelector(".swiper-pagination-bullet-active");
          progressCircle.style.setProperty("--progress", 1 - progress);
        },
        slideChangeTransitionEnd:(swiper)=> {
          this.updateTabIndex(swiper);
        }
      }
    }
    else {
      this.navigationValues = {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
      this.autoplayValues = false;
      this.onChangeValue = false;
    }


    this.settings = {
      modules: [Navigation, Pagination, Autoplay],
      navigation: this.navigationValues,
      pagination: this.paginationValues,
      autoplay: this.autoplayValues,
      slidesPerView: "1",
      loop: true,
      on:this.onChangeValue,
    };

    this.swiper;
  }

  handleResize() {
  }




  mount() {
    this.settings = {
      ...this.settings,
    }

    this.swiper = new Swiper(this.swiperElement, this.settings);
    window.addEventListener('resize', this.handleResize);

    if (this.swiperElement.classList.contains("c-slider--product")) {

      const buttomAutoplayButtos = document.querySelectorAll(".c-button--autoplay");

      for (let i = 0; i < buttomAutoplayButtos.length; i++) {
        const element = buttomAutoplayButtos[i];
        let swiperProduit = this.swiper;
        element.addEventListener("click", function() {
          if(element.getAttribute('data-state') == "play") {
            element.setAttribute('data-state', 'pause');
            element.querySelector(".c-button__label").innerHTML ="Play";
            element.querySelector(".c-button__icon").innerHTML ='<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="18" height="18" height viewBox="0 0 32 32"><path fill="currentColor" d="M9.336 8v16l13.333-8L9.336 8Z"/></svg>';
            swiperProduit.autoplay.stop();
          }else {
            element.setAttribute('data-state', 'play');
            swiperProduit.autoplay.start();
            element.querySelector(".c-button__label").innerHTML ="Pause";
            element.querySelector(".c-button__icon").innerHTML ='<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="18" height="18" viewBox="0 0 18 18"><path fill="currentColor" d="M9 1.5C4.864 1.5 1.5 4.864 1.5 9s3.364 7.5 7.5 7.5 7.5-3.364 7.5-7.5S13.136 1.5 9 1.5ZM9 15c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6-2.692 6-6 6Z"/><path fill="currentColor" d="M9.75 6.75h1.5v4.5h-1.5v-4.5Zm-3 0h1.5v4.5h-1.5v-4.5Z"/></svg>';
          }

        })
      }
    }


  }

  updateTabIndex(){
    let slides = document.querySelectorAll(".swiper-slide");

    for (let i = 0; i < slides.length; i++) {
      const element = slides[i];
      if (element.classList.contains("swiper-slide-active")) {
        let slideButtons=element.querySelectorAll(".js-slider-button");
        for (let index = 0; index < slideButtons.length; index++) {
          const elementButton = slideButtons[index];
          elementButton.setAttribute('tabIndex', '0');
        }
      }else {
        let slideButtons=element.querySelectorAll(".js-slider-button");
        for (let index = 0; index < slideButtons.length; index++) {
          const elementButton = slideButtons[index];
          elementButton.setAttribute('tabIndex', '-1');
        }
      }

    }
  }

  unmount() {
    this.destroy(this.swiper, true);
  }
}
