import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Loadix {
  constructor(element) {
    this.element = element;
    this.details = this.element.querySelectorAll(".js-loadix-detail");
    this.buttons = this.element.querySelectorAll(".js-loadix-button");

    this.handleMouseenter = this._handleMouseenter.bind(this);
    this.handleMouseleave = this._handleMouseleave.bind(this);

    this.handleClickButton = this._handleClickButton.bind(this);

    this.selectedId;
    this.selectedDetail;

  }

  mount() {
    this.details.forEach((element) => {
      element.addEventListener('mouseenter', this.handleMouseenter);
      element.addEventListener('mouseleave', this.handleMouseleave);
    });

    this.buttons.forEach((element) => {
      element.addEventListener('click', this.handleClickButton);
    });
  }

  _handleClickButton(e) {
    for (let i = 0; i < this.details.length; i++) {
      const element = this.details[i];
      if (element.classList.contains("c-loadix__detail--active")) {
        element.classList.remove("c-loadix__detail--active")
      }
    }

    this.selectedId = e.target.id;
    let newId = this.selectedId.replace("-button", "");
    document.getElementById(newId).classList.add("c-loadix__detail--active");
  }

  _handleMouseenter(e) {
    for (let i = 0; i < this.buttons.length; i++) {
      const element = this.buttons[i];
      element.className = "c-loadix__button js-loadix-button";
    }

    this.selectedDetail = e.target.querySelector("summary");
    this.selectedId = this.selectedDetail.id;

    let newId = this.selectedId.replace("-control", "-button");

    document.getElementById(newId).classList.add("c-loadix__button--active");
  }

  _handleMouseleave(e) {
    for (let i = 0; i < this.buttons.length; i++) {
      const element = this.buttons[i];
      element.className = "c-loadix__button js-loadix-button";

    }
  }

  unmount() {
    this.destroy(this.swiper, true);
  }
}
